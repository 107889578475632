<template>
	<div class="reLand" id="reLand">
		<keep-alive>
			<router-view></router-view>
		</keep-alive>
	</div>
</template>

<script>
	export default {
		name: 'reLand'
	}
</script>

<style scoped>
	.reLand {
		width: 100%;
		height: 100%;
	}
</style>
